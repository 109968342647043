<template>
    <v-container fluid>
        <v-card>
            <v-card-title>
                {{ pageTitle }}
            </v-card-title>

            <slot
                name="table"
                :table-schema="tableSchema" />
        </v-card>
    </v-container>
</template>

<script>
export default {
    props: {
        pageTitle: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            tableSchema: {},
            appTableKey: 0,
        };
    },
    watch: {
        pageTitle: {
            immediate: true,
            handler() {
                this.initialize();
            },
        },
    },

    methods: {
        initialize() {
            this.tableSchema = this.getTableSchema(this.pageTitle);
            this.appTableKey++;
        },
    },
};
</script>
